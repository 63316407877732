import React from "react"
import { Link } from "gatsby"
import "../../../styles/icon-button-styles.scss"

const IconButton = ({ children, buttonLink, buttonImg, buttonAlt }) => (
  <div>
    <button className="button-icon">
      <img alt={buttonAlt} className="button-img" src={buttonImg} />
      <a href={`${buttonLink}`} className="button-link">
        {children}
      </a>
    </button>
  </div>
)

export default IconButton
