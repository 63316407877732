import React from "react"
import errorIG1 from "../images/error-ig-1.png"
import errorIG2 from "../images/error-ig-2.png"
import errorIG3 from "../images/error-ig-3.png"
import errorIG4 from "../images/error-ig-4.png"
import errorIG5 from "../images/error-ig-5.png"
import errorIG6 from "../images/error-ig-6.png"

class ErrorBoundary extends React.Component {
  render() {
    return (
      <div>
        <div className="error-instagram-wrapper">
          <a href="https://www.instagram.com/yesinspiredevents/">
            <img
              className="error-instagram"
              alt="error-instagram"
              src={errorIG1}
            />
          </a>
          <a href="https://www.instagram.com/yesinspiredevents/">
            <img
              className="error-instagram"
              alt="error-instagram"
              src={errorIG2}
            />
          </a>
          <a href="https://www.instagram.com/yesinspiredevents/">
            <img
              className="error-instagram"
              alt="error-instagram"
              src={errorIG3}
            />
          </a>
          <a href="https://www.instagram.com/yesinspiredevents/">
            <img
              className="error-instagram"
              alt="error-instagram"
              src={errorIG4}
            />
          </a>
          <a href="https://www.instagram.com/yesinspiredevents/">
            <img
              className="error-instagram"
              alt="error-instagram"
              src={errorIG5}
            />
          </a>
          <a href="https://www.instagram.com/yesinspiredevents/">
            <img
              className="error-instagram"
              alt="error-instagram"
              src={errorIG6}
            />
          </a>
        </div>
      </div>
    )
  }
}

export default ErrorBoundary
