import React from "react"
import IconButton from "../button/icon-button"
import "../../../styles/footer-styles.scss"
import { Link } from "gatsby"
import instagramIcon from "../../images/instagram_icon.svg"
import facebookIcon from "../../images/facebook_icon.svg"
import pinterestIcon from "../../images/pinterest_icon.svg"
import mainLogo from "../../images/main_logo_mobile_white.svg"
import ErrorBoundary from "../error-boundry"

const Footer = () => (
  <div>
    <div className="instagram-wrapper">
      <div className="instagram-body">
        <h3 className="instagram-body-sub-heading">take a look</h3>
        <h4 className="instagram-body-heading">Latest from Instagram</h4>
        <button className="footer-button">
          <a
            className="footer-button-link"
            href="https://www.instagram.com/yesinspiredevents/"
          >
            @YESinspired
          </a>
        </button>
      </div>
      <ErrorBoundary></ErrorBoundary>
    </div>
    <footer className="footer">
      <div className="footer-text-container">
        <img
          alt="YESInspired logo"
          src={mainLogo}
          className="footer-text-logo"
        />
        <p className="footer-text">
          YesInspired Events is an event planning company that has the perfect
          solutions, experiences, and ideas to make your next celebration extra
          special. From engagement parties and bachelorette parties to corporate
          retreats and lavish weddings, our event planners customize every
          detail to make the most of your event.
        </p>
        <div className="footer-text-buttons">
          <IconButton
            buttonAlt="instagram icon"
            buttonImg={instagramIcon}
            buttonLink="https://www.instagram.com/yesinspiredevents/"
          >
            Instagram
          </IconButton>
          <IconButton
            buttonAlt="facebook icon"
            buttonImg={facebookIcon}
            buttonLink="https://www.facebook.com/yesinspired.events"
          >
            Facebook
          </IconButton>
          <IconButton
            buttonAlt="pinterest icon"
            buttonImg={pinterestIcon}
            buttonLink="https://www.pinterest.ca/yesinspiredevents/"
          >
            Pinterest
          </IconButton>
        </div>
        <div className="footer-bottom-links-list-container">
          <ul className="footer-bottom-links-list">
            <li className="footer-bottom-links">All rights Reserved</li>
            <li className="footer-bottom-links">
              © YESinspired {new Date().getFullYear()}
            </li>
            <li className="footer-bottom-links">
              <Link to="/terms-of-service" className="footer-bottom-links">
                Legal
              </Link>
            </li>
            <li className="footer-bottom-links">Made with Love</li>
          </ul>
        </div>
      </div>

      <div className="footer-links-container">
        <div className="footer-links-heading-wrap">
          <h3 className="footer-links-sub-heading">look around</h3>
          <h4 className="footer-links-heading">Explore Some More</h4>
        </div>
        <div className="footer-links-wrapper">
          <ul className="footer-links-list">
            <li className="footer-links-item">
              <Link className="footer-links-link" to="/about">
                About Us
              </Link>
            </li>
            <li className="footer-links-item">
              <Link className="footer-links-link" to="/services">
                Services
              </Link>
            </li>
            <li className="footer-links-item">
              <Link className="footer-links-link" to="/gallery">
                Gallery
              </Link>
            </li>
          </ul>
          <ul className="footer-links-list">
            <li className="footer-links-item">
              <Link to="/bridal-boxes" className="footer-links-link">
                Bridal Boxes
              </Link>
            </li>
            <li className="footer-links-item">
              <Link className="footer-links-link" to="/vendors">
                Vendors
              </Link>
            </li>
            <li className="footer-links-item">
              <Link className="footer-links-link" to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
)

export default Footer
